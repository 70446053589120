import * as React from 'react';
import { Helmet } from 'react-helmet';

export default function Seo(
  { title, description, image }:
    { title?: string; description?: string; image?: string },
) {
    return (
        <Helmet title={title || 'Sowos'} lang="es-mx">
            <meta
                name="facebook-domain-verification"
                content={process.env.GATSBY_FACEBOOK_DOMAIN_VERIFICATION}
            />
            <meta
                name="description"
                content={description || 'Con SOWOS podrás construir una fuente de ingresos alternativa, además de poder acceder a productos financieros justos. ¡Todo en un solo lugar!'}
            />
            <meta
                property="og:title"
                content="Mientras todos buscan que gastes, SOWOS quiere que ganes."
            />
            <meta
                property="og:og:site_name"
                content="Sowos"
            />
            <meta
                property="og:url"
                content="https://sowos.io"
            />
            <meta
                property="og:description"
                content={description || 'Con SOWOS podrás construir una fuente de ingresos alternativa, además de poder acceder a productos financieros justos. ¡Todo en un solo lugar!'}
            />
            <meta
                property="og:type"
                content="website"
            />
            <meta
                property="og:image"
                content={image || '/logo_sowos.svg'} />
        </Helmet>
    )
}
