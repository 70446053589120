// Domain
import { LandingPageContactRepository } from '../../domain/repository';
import { LandingPageContactEntity } from '../../domain/entities';

export class LandingPageContactCreator {
  private landing_page_contact_repository;

  constructor(landing_page_contact_repository: LandingPageContactRepository) {
    this.landing_page_contact_repository = landing_page_contact_repository;
  }

  createContact(landing_page_contact: LandingPageContactEntity, token: string): Promise<void> {
    return this.landing_page_contact_repository.create(landing_page_contact, token);
  }
}
