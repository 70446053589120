// Domain
import { LandingPageContactEntity } from '../../../domain/entities';
import { LandingPageContactRepository } from '../../../domain/repository';

export class FetchLandingPageContactRepository implements  LandingPageContactRepository{
  private readonly endpoint;

  constructor(endpoint?: String) {
    this.endpoint = endpoint || `${process.env.GATSBY_SOWOS_API_URL}/api/v1/app/landing-page-contact/`;
  }
  async create(landing_page_contact: LandingPageContactEntity, token: string): Promise<void> {
    const request_init = {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'X-CAPTCHA': token},
      body: JSON.stringify(landing_page_contact),
    }
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(this.endpoint, request_init)
        if (response.ok) {
          resolve(null);
        }
        reject(response.status)
      } catch (error) {
        console.log(error);
      }
    });
  };
}
