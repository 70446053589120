import uuid from 'uuid/v4';

// Infrastructure
import {FetchLandingPageContactRepository} from "../modules/langing_page_contact/infrastructure/repository/fetch";

// Application
import {LandingPageContactCreator} from "../modules/langing_page_contact/application/services";

export class SubscriptionViewModel {
  private readonly contact_id;

  constructor() {
    this.contact_id = uuid();
  }

  validateEmailFormat = (email: string) => {
    const regex_email = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return email.toLowerCase().match(regex_email);
  }

  subscribe = async (email: string, token: string) => {
    try {
      const landing_page_contact_repository = new FetchLandingPageContactRepository();
      const landing_page_contact_creator = new LandingPageContactCreator(landing_page_contact_repository);
      const landing_page_contact = {
        id: this.contact_id,
        email_address: email,
        was_consumed: false,
      }
      await landing_page_contact_creator.createContact(landing_page_contact, token);
      return true;
    } catch {
      return false;
    }
  }
}
