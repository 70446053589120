import * as React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import logo from "../images/logo_sowos_positivo.svg";

export default function NavBar() {
  return (
    <Navbar collapseOnSelect expand="lg">
      <Container className="my-3">
        <Navbar.Brand href="/" className="m-auto">
          <img
              src={logo}
              alt="Imagen del Logo de SOWOS."
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="my-4 my-md-2 ms-auto d-flex">
          <Nav.Link
              href="/SowosPocket"
              className="nav-link text-grey fw-normal m-auto px-5"
            >
              SOWOS Pocket
            </Nav.Link>
            <Nav.Link
              href="/SowosWealth"
              className="nav-link text-grey fw-normal m-auto px-5"
            >
              SOWOS Wealth
            </Nav.Link>
            <Nav.Link
              href={`${process.env.GATSBY_APP_URL}/login`}
              className="nav-link active text-danger fw-bold m-auto px-5"
            >
              INICIAR SESIÓN
            </Nav.Link>
            <Nav.Link
              href={`${process.env.GATSBY_APP_URL}/sign-up`}
              className="btn btn-danger text-white fw-bold me-auto px-3 m-auto mt-3 py-3 mt-lg-auto"
            >
              REGÍSTRATE
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
