import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import logo from "../images/logo_sowos_by_columbus_white.svg";

export default function Footer() {
  return (
    <div className="bg-primary">
      <div className="container-xl pt-5">
        <div className="row">
          <div className="col-4 mb-5">
            <img
              src={logo}
              alt="Logo SOWOS"
            />
          </div>
        </div>
        <div className="row text-white">
          <div className="col-12 col-md-4">
            <div>
              © 2021 Derechos Reservados
            </div>
            <div  className="mt-5 mt-md-3">
              <a href="mailto:contacto@sowos.io"  rel="noreferrer">contacto@sowos.io</a>
            </div>
            <div className="mt-5 mt-md-3">
              +52 (55) 5377 2823
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="mt-5 mt-md-0">
              <a href="https://columbus.mx/" target="_blank" rel="noreferrer">
                Sobre Columbus
              </a>
            </div>
            <div className="mt-5 mt-md-3">
              <a href="https://columbus.mx/" target="_blank" rel="noreferrer">
                Reclamaciones
              </a>
            </div>
            <div className="mt-5 mt-md-3">
              <a href="https://columbus.mx/"  target="_blank" rel="noreferrer">
                Guía de servicios de inversión
              </a>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div  className="mt-5 mt-md-0">
              <a
                href={`${process.env.GATSBY_LEGALS_URL}/AVISO_DE_PRIVACIDAD_SOWOS_io.pdf`}
                target="_blank"
                rel="noreferrer"
                className="text-white py-2"
              >
                Aviso de Privacidad
              </a>
            </div>
            <div  className="mt-5 mt-md-3">
              <a
                href={`${process.env.GATSBY_LEGALS_URL}/TYC_SOWOS_io.pdf`}
                target="_blank"
                rel="noreferrer"
                className="text-white py-2"
              >
                Términos y condiciones
              </a>
            </div>
            <div  className="mt-5 mt-md-3">
              <a
                href="https://www.instagram.com/sowos.io/"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  className="me-2"
                  src="../images/instagram.svg"
                  alt="Instagram"
                  placeholder="none"
                />
              </a>
              <a
                href="https://www.facebook.com/SowosMX/"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  className="mx-2"
                  src="../images/facebook.svg"
                  alt="Facebook"
                  placeholder="none"
                />
              </a>
              <a
                href="https://twitter.com/SOWOSMX"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  className="mx-2"
                  src="../images/twitter.svg"
                  alt="Twitter"
                  placeholder="none"
                  width={30}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/sowosmx/"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  className="mx-2"
                  src="../images/linkedin.svg"
                  alt="Linkedin"
                  placeholder="none"
                  width={25}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="row pt-5">
          <hr className="bg-white" />
          <p className="text-grey">
            Columbus de México S.A de C.V. Asesor en Inversiones Independiente tiene el número de folio de inscripción 30005 en el Registro de
            Asesores en Inversiones asignado por la Comisión Nacional Bancaria y de Valores.
          </p>
          <p className="text-grey">
            Respecto de los servicios contratados con la plataforma, la CNBV supervisa exclusivamente la prestación de servicios (i) de
            administración de cartera de valores cuando Columbus de México toma decisiones de inversión a nombre y por cuenta de sus
            clientes y (ii) asesoría de inversión en valores (incluye fondos de inversión), análisis y emisión de recomendaciones de inversión de
            manera individualizada, por lo que la CNBV carece de atribuciones para supervisar o regular cualquier otro servicio que proporcione la
            Plataforma.
          </p>
          <p className="text-grey">
            En el caso de sowos Pocket 1. Los recursos que aporten estarán invertidos en valores mediante su contrato de intermediación bursátil
            que aperturen en KUSPIT CASA DE BOLSA, S.A. DE C.V., una entidad financiera constituida de conformidad con las leyes mexicanas,
            regulada y supervisada por la Comisión Nacional Bancaria y de Valores, misma que cuenta con Autorización número 210-90283/2010,
            para organizarse y operar como una Casa de Bolsa conforme a la Ley del Mercado de Valores.
          </p>
        </div>
      </div>
    </div>
  );
}
